export const KukhniaServiceData = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
  { img: "014" },
  { img: "015" },
  { img: "016" },
  { img: "017" },
];

export const RizneServiceData = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
  { img: "014" },
  { img: "015" },
  { img: "016" },
  { img: "017" },
  { img: "018" },
  { img: "019" },
  { img: "020" },
  { img: "021" },
  ];

  export const MebliUvannuServiceData = [
    { img: "001" },
    { img: "002" },
    { img: "003" },
    { img: "004" },
    { img: "005" },
    { img: "006" },
    { img: "007" },
    { img: "008" },
    { img: "009" },
    { img: "010" },
    { img: "011" },
    { img: "012" },
    { img: "013" },
    { img: "014" },
    { img: "015" },
    { img: "016" },
    { img: "017" },
    { img: "018" },
    { img: "019" },
    { img: "020" },
    { img: "021" },
  ];

  export const PrykhozhiServiceData = [
    { img: "001" },
    { img: "002" },
    { img: "003" },
    { img: "004" },
    { img: "005" },
    { img: "006" },
    { img: "007" },
    { img: "008" },
    { img: "010" },
    { img: "011" },
  ];
  export const ShafaKupeServiceData = [
    { img: "001" },
    { img: "002" },
    { img: "003" },
    { img: "004" },
    { img: "005" },
    { img: "006" },
    { img: "007" },
    { img: "008" },
    { img: "009" },
    { img: "010" },
    { img: "011" },
    { img: "012" },
    { img: "013" },
    { img: "014" },
    { img: "015" },
    { img: "016" },
    { img: "017" },
    { img: "018" },
    { img: "020" },
    { img: "021" },
    { img: "022" },
    { img: "023" },
  ];
  export const GarderobniServiceData = [
    { img: "001" },
    { img: "002" },
    { img: "003" },
    { img: "004" },
    { img: "005" },
    { img: "006" },
    { img: "007" },
    { img: "008" },
  ];
  