import Carousel from "react-bootstrap/Carousel";
import "bootstrap/dist/css/bootstrap.min.css";
import styles from "./FurnitureKey.module.css";
import FurnitureKeyBlock from "../../Component/MainContent/MebliPidKeyMiddleBlock/General";
import FirstTextBlock from "../../Component/MainContent/PageText/FirstTextBlock/FirstTextBlock";
function FurnitureKey() {

  return (
    <div>
<FurnitureKeyBlock/>
<FirstTextBlock/>
</div>
  );
}

export default FurnitureKey;
