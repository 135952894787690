
export const HowToOrderServiceData = [
    {
      img: "phonecall",
      header:"Телефонуєте",
    },
    {
      img: "sedan",
      header:"Приїжджає замірник",
    },
    {
      img: "contract",
      header:"Отримуєте попередній ескіз і кошторис",
    },
    {
      img: "handshake",
      header:"Погодження проекту",
    },
    {
        img: "saw",
        header:"Запуск в роботу",
      },
      {
        img: "worker",
        header:"Доставка та монтаж",
      },
   
  ];
  