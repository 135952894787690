export const FurnitureKeyServiceData = [
  { header: "Проект 1" },
  { header: "Проект 2" },
  { header: "Проект 3" },
  { header: "Проект 4" },
  { header: "Проект 5" },
  { header: "Проект 6" },
];
export const ProjectFirstImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
];
export const ProjectSecondImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
  { img: "014" },
  { img: "015" },
];
export const ProjectThirdImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
];
export const ProjectFourImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
];
export const ProjectFiveImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
  { img: "014" },
  { img: "015" },
  { img: "016" },
  { img: "017" },
  { img: "018" },
  { img: "019" },
  { img: "020" },
  { img: "021" },
];export const ProjectSixImage = [
  { img: "001" },
  { img: "002" },
  { img: "003" },
  { img: "004" },
  { img: "005" },
  { img: "006" },
  { img: "007" },
  { img: "008" },
  { img: "009" },
  { img: "010" },
  { img: "011" },
  { img: "012" },
  { img: "013" },
  { img: "014" },
  { img: "015" },
  { img: "016" },
  { img: "017" },
  { img: "018" },
  { img: "019" },
  { img: "020" },
  { img: "021" },
  { img: "022" },
  { img: "023" },
  { img: "024" },
  { img: "025" },
  { img: "026" },
];
export const ProjectLocation = [
  { project: "ProjectFirst" },
  { project: "ProjectSecond" },
  { project: "ProjectThird" },
  { project: "ProjectFour" },
  { project: "ProjectFive" },
  { project: "ProjectSix" },
];
