export const ProjectPageLinkServiceData = [
  {
    header: "Проект 1",
    link: "/project/first"
  },
  {
    header: "Проект 2",
    link: "/project/second"
  },
  {
    header: "Проект 3",
    link: "/project/third"
  },
  {
    header: "Проект 4",
    link:"/project/four"
  },
  {
    header: "Проект 5",
    link: "/project/five"
  },
  {
    header: "Проект 6",
    link: "/project/six"
  },
];
