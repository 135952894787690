export const ContactServiceData = [
    {
      header: "Адреса",
      infoFirst:"81625,Вул.Шевченка 9б",
      infoSecond:"м.Львів,Україна  "
    },
    {
        header: "Години роботи",
      infoFirst:"Понеділок-П'ятниця: 9:00-19:00",
      infoSecond:"Субота: 10:00 - 18:00 " 
    },
    {
        header: "Контакти",
        infoFirst:"+380-67-273-92-83",
        infoSecond:"lviv-mebli@ukr.net"
    }
  ];
  