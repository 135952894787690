export const MainPageCarouselServiceData = [
  {
    img: "001",
  },
  {
    img: "002",
  },
  {
    img: "003",
  },
  {
    img: "004",
  },
  {
    img: "005",
  },
  {
    img: "006",
  },
];
